import React from "react";

import Breadcrumbs from "@/components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@/components/common/Breadcrumbs/types";
import SeoHeaders from "@/components/common/seoHeaders";
import ActivationForm from "@/components/profile/promoCodes/activationForm";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import Constructor from "../constructor";
import { InfoPageProps } from "../infoPage/types";

import "./styles.scss";

const PromoCode = ({ pageContext }: InfoPageProps) => {
  const {
    seoId,
    title = "Информация о Lunaro",
    description = "Информация о Lunaro",
    text,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    url,
    zone,
    expertsInitial,
  } = pageContext;

  return (
    <>
      <SeoHeaders title={title} description={description} url={url} />
      <div className="page-width">
        <div className="promo-page">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Page}
            showOnPage={false}
          />
          <div>
            {!!header && <h1 className="promo-page__title">{header}</h1>}
            <div
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(text || ""),
              }}
            />
            <ActivationForm />
          </div>
        </div>
        <Constructor zone={zone} expertsInitial={expertsInitial} />
      </div>
    </>
  );
};

export default PromoCode;
